import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../utils.service';

import {HttpClient} from '@angular/common/http';


@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})

export class SuccessComponent implements OnInit {

    API = this.u.getApi();
    params: any;
    _patient = JSON.parse(localStorage.getItem("currentPatient"));
    _clinic = JSON.parse(localStorage.getItem("currentClinic"));

    constructor(private http: HttpClient, public u: UtilsService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.params = this.route.snapshot.queryParams;
        const response = this.http.post(this.u.getApi()  + '/api/send-success-message', {
            token: this.params.token,
            clinic: this._clinic,
            message_sent: true,
            active: false,

        })
            .subscribe(resp => {
                console.log('message sent', resp);
            });

        console.log('response', response);
    }

    navigateFurther(e) {
        e.preventDefault();

        // this.http.post(this.API + '/api/test', this.params)
        // .subscribe(
        //   res => {
        //     console.log(res);
        //   },
        //   err => {
        //     console.log("Error occured");
        //   }
        // );

        this.router.navigate(['/sog']);
    }
}
