import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidatorFn, Validator, FormControl } from '@angular/forms';


// validator function
export function validateChecksum(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {

    var coefficients = [4,3,2,7,6,5,4,3,2,1];
    var checksum = 0;
    var digits = control.value.replace("-","").split("").map(el=>parseInt(el));
    for (var i=0;i<digits.length;i++){
      checksum += coefficients[i]*digits[i];
    }

    console.log("--validate CPR-->> all digits -", digits, "checksum - ", checksum, "checksum mod 11 - ", checksum%11);

    return checksum%11 !== 0 ? {'custom': {"checksumMod11": checksum%11}} : null;
  };
}


@Directive({
  selector: '[customValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: customValidatorDirective, multi: true }]
})
export class customValidatorDirective implements Validator {
  @Input('customValidator') type;
  validator: ValidatorFn;

    validate(control: AbstractControl): {[key: string]: any} | null {
      var types = [undefined, "null", null];
      if (this.type.CPR && types.indexOf(control.value) === -1  && control.value.replace("-","").length === 10){
        return validateChecksum()(control);
      }

      return null;
    }

}
