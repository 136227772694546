import {Component, OnInit, NgZone} from '@angular/core';
import {Router, ActivatedRoute, NavigationExtras} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils.service';

import * as _ from 'underscore';
import 'rxjs/add/operator/map';
import * as $ from 'jquery';

declare var google: any;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {

    values = "";
    searchHelp = "assets/images/search-help.png";
    mapHelp = "assets/images/map-help.png";
    fysioMarker = "assets/img/pin.svg";
    allClinics = JSON.parse(localStorage.getItem("allClinics"));
    clinicsToShow = [];
    foundClinics = [];
    areaClinics = [];
    nearClinics = [];
    chosenSpecialists = [];
    selectedClinics = [];
    lastBooking = JSON.parse(localStorage.getItem('currentUser'));
    selectedClinicOverlay = [{_id: ""}];
    isSelectedClinicOverlay: boolean;
    mapMarkerIcon = "assets/img/pin_map.svg";
    mapMarkerIconSelected = "assets/img/pin_map_selected.svg";
    googleMaps: any;
    oldMapMarker: any;
    token: string;

    API = this.u.getApi();

    params: any;
    _patient: any;

    constructor(private _ngZone: NgZone, private http: HttpClient, private route: ActivatedRoute, public u: UtilsService, private router: Router) {
        this.token = this.route.snapshot.queryParamMap.get("token");
    }

    ngOnInit() {
        this.setPatient();

        this.params = this.route.snapshot.queryParams;
        this.token = this.route.snapshot.queryParamMap.get("token")

        console.log('<--- in find  clinic  --->');

        var self = this;

        // this.http.get("https://212.71.247.241:30selectedClinicOverlay/api/all-clinics")
        const response = self.getBiData();

        if (response.status !== 200) {
            alert('Bi data error response: ' + response.status);
        }

        // this.http.get(`https://fbooking.adevo.io:3000/api/all-clinics`)
        this.http.get(this.u.getApi() + '/api/all-clinics/')
            .map((res: any) => {
                return res;
            })
            .subscribe((res: any) => {
                if (res.status == 200) {
                    var clinics = res.response;
                    self.allClinics = clinics.filter(el => el.danica === 1);

                    self.clinicsToShow = self.allClinics.filter(el => el.is_super_clinic === false);
                    if (this.params.postCode) {
                        self.clinicsToShow = self.clinicsToShow.filter(el => el.post_code === this.params.postCode);
                    }
                    // console.log("CLINICS TO SHOW", self.clinicsToShow);
                    self.foundClinics = self.clinicsToShow;
                    var map = self.addMap();

                    self.googleMaps = map;
                    self.addMapListeners(map, self.allClinics);
                    // self.addMapListeners(map, clinics);
                    // var searchBox = self.addSearBox();
                    // searchBox.setBounds(map.getBounds());
                    // self.addSearBoxListeners(searchBox, map);

                    if (_.isObject(self.lastBooking) && _.isObject(self.lastBooking.lastBooking)) {
                        self.selectedClinics = _.union(self.selectedClinics, [{
                            _id: self.lastBooking.lastBooking.clinicId,
                            value: self.lastBooking.lastBooking.clinicName,
                        }]);
                    }
                    self.isSelectedClinicOverlay = false;
                } else {
                    console.error("Error", res);
                }

            });


    }


    getBiData() {
        return JSON.parse(this.u.getBiData());
    }

    getClinics() {
        return this.u.getClinics();
    }


    addMap() {
        let self = this;

        let mapProp = {
            // center: new google.maps.LatLng( 56.0514992, 10.406087599999978),
            center: new google.maps.LatLng(55.639383, 12.574779000000035),
            zoom: 6,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: true,
            styles: [{
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [{'color': '#e9e9e9'}, {'lightness': 17}]
            }, {
                'featureType': 'landscape',
                'elementType': 'geometry',
                'stylers': [{'color': '#f5f5f5'}, {'lightness': 20}]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [{'color': '#ffffff'}, {'lightness': 17}]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry.stroke',
                'stylers': [{'color': '#ffffff'}, {'lightness': 29}, {'weight': 0.2}]
            }, {
                'featureType': 'road.arterial',
                'elementType': 'geometry',
                'stylers': [{'color': '#ffffff'}, {'lightness': 18}]
            }, {
                'featureType': 'road.local',
                'elementType': 'geometry',
                'stylers': [{'color': '#ffffff'}, {'lightness': 16}]
            }, {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [{'color': '#f5f5f5'}, {'lightness': 21}]
            }, {
                'featureType': 'poi.park',
                'elementType': 'geometry',
                'stylers': [{'color': '#dedede'}, {'lightness': 21}]
            }, {
                'elementType': 'labels.text.stroke',
                'stylers': [{'visibility': 'on'}, {'color': '#ffffff'}, {'lightness': 16}]
            }, {
                'elementType': 'labels.text.fill',
                'stylers': [{'saturation': 36}, {'color': '#333333'}, {'lightness': 40}]
            }, {'elementType': 'labels.icon', 'stylers': [{'visibility': 'off'}]}, {
                'featureType': 'transit',
                'elementType': 'geometry',
                'stylers': [{'color': '#f2f2f2'}, {'lightness': 19}]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry.fill',
                'stylers': [{'color': '#fefefe'}, {'lightness': 20}]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry.stroke',
                'stylers': [{'color': '#fefefe'}, {'lightness': 17}, {'weight': 1.2}]
            }]
        };

        return new google.maps.Map(document.getElementById('map'), mapProp);
    }

    /**
     * Need to extract
     */
    setPatient() {
        this.http.get(this.u.getApi() + '/api/patient/' + this.token)
            .map((resp: any) => resp)
            .subscribe((resp: any) => {
                // const decodedResponse = JSON.parse(resp.response);
                this._patient = resp.response;
                console.log("patient in map: ",this._patient);
            });
    }

    addMapListeners(map, clinics) {
        var self = this;

        var iniMarkers = self.initializeMarkers(map, clinics);

        // Bias the SearchBox results towards current map's viewport. Get current clinics
        map.addListener('bounds_changed', function () {
            this.areaClinics = [];
            self.areaClinics = [];

            this.nearClinics = [];
            self.nearClinics = [];
            _.each(iniMarkers, iniMarker => {
                if (map.getBounds().contains(iniMarker.getPosition())) {
                    self.areaClinics.push(iniMarker);
                }
            });

            this.areaClinics.push(self.areaClinics);
            var near = self.findClosestN(map.getCenter(), iniMarkers, 3);
            self.nearClinics = near;
            this.nearClinics.push(self.nearClinics);
            self.refreshFilters();
        });

        // console.log('area clinics, ', self.areaClinics);
    }


    addSearBox() {
        var options = {
            types: ['(cities)'],
            componentRestrictions: {country: 'dk'}
        };
        return new google.maps.places.Autocomplete(document.getElementById('search-address'), options);
    }


    addSearBoxListeners(searchBox, map) {
        var self = this;

        var markers = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('place_changed', () => {

            // var helperRegion = self.getHelperRegion();
            // helperRegion.style.display = 'block';
            //
            // var helper = self.getHelper();
            // helper.style.display = 'none';

            var place = searchBox.getPlace();
            console.log('places: ', place);

            if (place.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach(marker => {
                marker.setMap(null);
            });


            markers = [];

            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();


            if (!place.geometry) {
                console.error('Returned place contains no geometry');
                return;
            }

            var icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25)
            };

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }


            map.fitBounds(bounds);

        });

    }

    findClosestN(pt, iniMarkers, numberOfResults) {

        var self = this;

        var closest = _.map(iniMarkers, marker => {
            marker.distance = google.maps.geometry.spherical.computeDistanceBetween(pt, marker.getPosition());
            return marker;
        });

        // closest.sort(self.sortByDist);
        return closest.splice(0, numberOfResults);
    }


    initializeMarkers(map, clinics) {
        var self = this;

        var image = {
            url: self.mapMarkerIcon,
            size: new google.maps.Size(25, 35)
        };

        var shape = {
            coords: [1, 1, 1, 20, 18, 20, 18, 1],
            type: 'poly'
        };

        var clinicMarkers = _.map(clinics, clinic => {
            var clinicMarker = new google.maps.Marker({
                position: new google.maps.LatLng(parseFloat(clinic.latitude), parseFloat(clinic.longitude)),
                map: map,
                title: clinic.name,
                _id: clinic.id,
                draggable: false,
                shape: shape,
                zIndex: 4,
                icon: image
            });

            // console.log("LANG",parseFloat(clinic.latitude));
            // console.log("LONG",parseFloat(clinic.longitude));

            clinicMarker.addListener('click', () => {
                self.selectMarker(clinic, clinicMarker);
            });

            google.maps.event.addListener(clinicMarker, 'touchstart', () => {
                self.selectMarker(clinic, clinicMarker);
            });
            //
            return clinicMarker;
        });

        self.oldMapMarker = clinicMarkers[clinicMarkers.length - 1];

        return clinicMarkers;
    }

    getHelperRegion() {
        return document.getElementById('helper-region');
    }

// Get selected clinics

    fetchClinics(event: any, buttonID, buttonName) {


        let queryParams = JSON.parse(JSON.stringify(this.params));
        queryParams['clinicId'] = buttonID;
        queryParams['clinicName'] = buttonName;

        let currentClinic = this.clinicsToShow.find((value) => {
            return value.id === buttonID;
        });

        const data = {
            token: this.token,
            name: currentClinic.name,
            address: currentClinic.address,
            phone: currentClinic.phone,
            email: currentClinic.email,
            clinicID: buttonID,
            clinicName: currentClinic.name,
            // clinicName:
        }

        localStorage.setItem('currentClinic', JSON.stringify(data));
        this.http.post(this.u.getApi() + '/clinics/save', data)
            .subscribe((resp: any) => {
                console.log('some sort of resp', resp);
                if (resp.status == 200) {
                  this.updatePatient(buttonID, buttonName);

                } else {
                  console.log("error -",resp);

                }

            });

    }

    updatePatient(clinicID, clinicName) {
        console.log('updating', this.token, clinicID, clinicName);

        this.http.post(this.u.getApi() + '/api/patient/', {
            token: this.token,
            clinicID,
            clinicName,
            active: true,
            message_sent: false
        }).subscribe((res:any) => {
          if (res.status == 200) {
            console.log("after /api/patient. resp - ", res);
            console.log("NAVIGATE to /success");
            this.router.navigate(['/success'], {queryParams: {token: this.token}});

          } else {
              console.error("Error", res);
          }

        });

    }

    isActive() {
        return 'active';
    }

    deleteSelectedClinic(event: any) {

        var self = this;

        console.log("areaclinics =>", self.areaClinics);

        var src_id = $(event.target).closest(".adevo-selected-clinic").attr("id");
        if (typeof (src_id) === "undefined") {
            src_id = event.target.id;
        }

        self.selectedClinics = _.reject(self.selectedClinics, clinic => {
            $("#" + src_id).prop('checked', false);
            // return clinic.id == src_id;
            return clinic._id == src_id;
        });
    }

    refreshFilters() {
        this._ngZone.run(() => {
        });
    }

    navigateToSpecify() {
        var current_user = JSON.parse(localStorage.getItem("currentUser"));
        if (current_user) {

            var clinics = _.map($(".adevo-selected-clinic"), clinic => clinic.getAttribute('id'));

            let navigationExtras: NavigationExtras = {
                queryParams: {'clinics': clinics},
            };
            if (clinics.length > 0) {
                this.router.navigate(['/specify-problem'], navigationExtras);
            }
        } else {
            this.router.navigate(['/login']);
        }

    }

    search(event: any) {
        var self = this;

        let clinicsArray = [];
        let searchQuery = event.target.value;
        var found = _.find(self.clinicsToShow, (clinic) => {
            return clinic.post_code == searchQuery;
        });

        if (found) {
            clinicsArray.push(found);
            self.foundClinics = clinicsArray;
        } else {
            self.foundClinics = self.clinicsToShow;
        }
    }

    selectMarker(marker: any, markerObject: any) {
        var self = this;

        $('.active').removeClass('active');
        self.selectedClinics = [];

        self.selectedClinics = _.union(self.selectedClinics, [{_id: marker._id, value: marker.name,}]);
        $("#" + marker._id).addClass('active');

        // Refresh Selected clinics on front-end
        self.refreshFilters();
    }

    nextSlide(event) {
        console.log('next step?', event.target.value);
    }

    updateSelectedMarkers(marker) {
        console.log(this.selectedClinicOverlay, marker._id);

        if (_.contains(this.selectedClinicOverlay, marker._id)) {
            console.log('added');
            this.selectedClinicOverlay = marker;
            this.isSelectedClinicOverlay = true;
        } else {
            console.log('not added');
        }
    }

}
