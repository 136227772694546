import {
    Component,
    OnInit,
    Renderer,
    ViewChild,
    AfterViewInit,
    Input
} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DataTableDirective} from 'angular-datatables';
import {UtilsService} from '../../utils.service';
import 'rxjs/add/operator/map';

import { AuthenticationService } from '../auth/service';



import * as _ from 'underscore';

class Person {
    id: number;
    firstName: string;
    lastName: string;
}

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Component({
    selector: 'app-sog',
    templateUrl: './sog.component.html',
    styleUrls: ['./sog.component.scss']
})


export class SogComponent implements AfterViewInit, OnInit {
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    min: number;
    max: number;
    searchAddress: string;
    searchFieldValue: string;
    actualRecords: number;
    showProfileComponent: boolean;
    showRegisterForm: boolean;

    API = this.u.getApi();

    dtOptions: DataTables.Settings = {};
    data: [];
    // dtTrigger: Subject<any> = new Subject<any>();
    status: string;
    token: string;

    loading = <boolean>false;
    notification = {message: "", type: ""};
    patients = [];
    patientProperties: any[] = [];
    searchQuery: string;
    allClinics: any;
    searchString = '';
    @Input() case_nr: string;

    constructor(private renderer: Renderer,
      private router: Router,
      private http: HttpClient,
      public u: UtilsService,
      private hp:HttpClient,
      private authenticationService: AuthenticationService) {
    }

    /**
     *  If questions about datatable:
     *  https://l-lin.github.io/angular-datatables/#/basic/angular-way
     */
    ngOnInit() {

        let self =this;
        // this.searchQuery = '0101019993';
        this.showProfileComponent = true;

        const that = this;
        that.status = 'data_not_found';

        let url = `${this.u.getApi()}/digiapi/get-patient-data/${this.searchQuery}`;

        that.dtOptions = {
            paging: false,
            pagingType: 'full_numbers',
            serverSide: true,
            processing: true,
            lengthChange: false,
            deferLoading: 1,
            dom: '<"top" >rt<"bottom"i><"pagination"p>',
            language: {search: '', zeroRecords: ''},
            columns: [
                {data: 'CPR'},
                {data: 'Email'},
                {data: 'FirstName'},
                {data: 'MiddleName'},
                {data: 'LastName'},
                {data: 'MobilePhone'},
            ],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .get<DataTablesResponse>(url)
                    .subscribe((resp: any) => {
                        callback({
                            recordsTotal: that.patients.length,
                            recordsFiltered: that.patients.length,
                            data: [],
                            draw: 1
                        });
                    });
            },
        };

        // this.hp.get(`https://fbooking.adevo.io:3000/api/all-clinics`)
        this.hp.get(`${this.u.getApi()}/api/all-clinics`)
          .map(res => res)
          .subscribe((res: any) => {
          if (res.status == 200) {
              var clinics = res.response;
              self.allClinics = clinics.filter(el => el.danica === 1);
              localStorage.setItem("allClinics", JSON.stringify(self.allClinics));
          } else {
              console.error("Error", res);
          }

        });
    }

    patientLookup() {
        let that = this;
        console.log(this.searchQuery);

        this.searchString = this.searchQuery; // why-tf is this needed?


        this.loading = true;
        // this.notification = {message: "LOADING", type: "loading"};

        let self = this;
        let url = `${this.u.getApi()}/digiapi/get-patient-data/${this.searchQuery}`;

        this.http.get<DataTablesResponse>(url)
            .subscribe(
              (resp: any) => {
                if (resp.status === 200) {
                    let userData = resp.response.DigifysPatientData;
                    // that.patients = resp.response.DigifysPatientData;
                    that.renderPatientLookup(userData);
                    that.status = 'data_found';
                } else {
                    that.status = 'data_not_found';
                    that.showProfileComponent = true;
                    // self.notification = {message: "CPR not found", type: "success"};
                    that.renderPatientLookup({});
                }
              },
              (err:any) => {
                // self.notification = {message: "ERROR", type: "error"};
                self.renderPatientLookup({});
              }
            );

            console.log(this.status);
    }

    renderPatientLookup(data) {
        let that = this;
        that.patientProperties = [];
        this.dtOptions.columns.map((resp) => {
            that.patientProperties.push(resp.data);
        });

        setTimeout(()=>{
            that.patients = [data];
            that.notification = {message: "", type: ""};
            that.loading = false;
        }, 1000);

    }

    selectPatient(patient) {
      let self = this;

      patient.case_nr = this.case_nr;
      this.loading = true;
      localStorage.setItem("currentPatient", JSON.stringify(patient));
      console.log("select patient: ",patient.case_nr);
      self.notification = {message: "Indlæser", type: "loading"};

      this.updateOrSavePatient(patient);
    }

    updateOrSavePatient(patient) {
        let url2 = this.u.getApi() + "/api/update-or-save-patient";

        const data = {
            mobil: patient.MobilePhone,
            email: patient.Email,
            firstName: patient.FirstName,
            lastName: patient.LastName,
            middleName: patient.MiddleName,
            userName: patient.UserName,
            case_nr: patient.case_nr,
            cpr: patient.CPR,
        };

        console.log("update or save patient: ",data);

        this.http.post(url2, data)
            .subscribe(
                (resp: any) => {
                    if (resp.status === 200) {
                        console.log('saved?', resp);
                        this.router.navigate(['/map'], {queryParams: {token: resp.response.token}});
                    // } else {
                    //     this.router.navigate(['/map'], {});
                    }
                },
                (err:any) => {
                    console.log('error 2.');
                }
            );
    }

    setSearch($event) {
        this.searchQuery = $event.target.value;
    }

    triggerForm() {
        this.status = 'register_user';
    }

    ngAfterViewInit(): void {
        console.log('after init');
    }

}
