import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from "@angular/common/http";
import {UtilsService} from "../../utils.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private jwt: JwtHelperService, private http: HttpClient, private u: UtilsService) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let userToken = '1';
        if (currentUser === null) {

            this.router.navigate(['/']);
        }

        if (typeof currentUser.token !== 'undefined') {
            userToken = currentUser.token;
        }

        this.http.post(this.u.getApi() + '/users/verify-token', {
            token: userToken
        })
            .subscribe((resp: any) => {
                if (resp.status !== 200) {
                    this.router.navigate(['/']);
                }
            });


        return true;
    }
}
