import { Component, OnInit} from '@angular/core';
import { LoginForm } from '../login/login-form';


import { UtilsService } from '../../utils.service';
import {Router} from "@angular/router";
import {AuthenticationService} from "../auth/service";

declare var require: any;
const _ = require('underscore');

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {

    constructor(
        public u: UtilsService,
        public router: Router,
        public authService: AuthenticationService
    ) {}

    ngOnInit() {
        this.authService.logout();

        this.router.navigate(['/sog']);
    }

}
