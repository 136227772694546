import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-forlob',
  templateUrl: './forlob.component.html',
  styleUrls: ['./forlob.component.scss']
})
export class ForlobComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToSpecify() {

      // let navigationExtras: NavigationExtras = {
      //   queryParams: {'clinics': clinics},
      // };
        this.router.navigate(['/success']);
  }

}
