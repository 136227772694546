import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MapComponent } from './components/map/map.component';
import { SogComponent } from './components/sog/sog.component';
import { ForlobComponent } from './components/forlob/forlob.component';
import { SuccessComponent } from './components/success/success.component';
import { SogProfilComponent } from './components/sog-profil/sog-profil.component';
import { BookingOversigtComponent } from './components/booking-oversigt/booking-oversigt.component';
import { ProfilComponent } from './components/profil/profil.component';

import { BodyPartsComponent } from './components/body-parts/body-parts.component';
import { BookTimeComponent } from './components/book-time/book-time.component';
import { SuccessPatientComponent } from './components/success-patient/success-patient.component';
import {RegisterComponent} from "./components/register/register.component";
import {AuthGuard} from "./components/auth/authguard";
import {LogoutComponent} from "./components/logout/logout.component";

// import {FakedataComponent} from './components/fakedata/fakedata.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'map', component: MapComponent, canActivate: [AuthGuard]  },
  { path: 'sog', component: SogComponent, canActivate: [AuthGuard]  },
  { path: 'forlob', component: ForlobComponent },
  { path: 'success', component: SuccessComponent, canActivate: [AuthGuard]  },
  { path: 'sog-profil', component: SogProfilComponent },
  { path: 'booking-oversigt', component: BookingOversigtComponent },
  { path: 'profil', component: ProfilComponent },
  { path: 'body-parts', component: BodyPartsComponent },
  { path: 'book-time', component: BookTimeComponent },
  { path: 'success-patient', component: SuccessPatientComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
