import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils.service';
import {AuthenticationService} from "../auth/service";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {
  loading = false;
  bookingFailed = false;
  notification = {message: "", type: ""};


  token = null;
  params: any;
  avatar = 'assets/img/login.png';
  API = this.u.getApi();

  selectedTime = JSON.parse(localStorage.getItem("selectedTime"));


  userData = {
    FirstName: '',
    LastName: '',
    MiddleName: '',
    Email: '',
    PostNr: '',
    MobilePhone: '',
    CPR: '',
    CaseNumber: '',
    complimentaId: '',
  };
  cpr: any;
  clinidID: any;
  terms = false;
  formFilled = false;
  _patient: any;
  allClinics: any;
  userFound: boolean;

  isRegisteredExternally: boolean = false;

  _clinic: any;
  _currentPatient: any;
  therapist = JSON.parse(localStorage.getItem("therapist"));


  constructor(
    private activatedRoute: ActivatedRoute,
    public u: UtilsService,
    private router: Router,
    private http: HttpClient,
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      if ('undefined' !== typeof params.token) {
        this.token = params.token; // Print the parameter to the console.
      }
    });
  }

  ngOnInit() {
    let self = this;
    // self.authService.logout();

    this.params = this.activatedRoute.snapshot.queryParams;
    this.formFilled = true;
    // self.notification = {message: "Login Vellykket", type: "success"};

    this.http.get(this.u.getApi() + '/api/active-patient/' + this.token)
      .subscribe(
        (resp: any) => {
          if (resp.status === 200 && resp.response) {
            self.userFound = true;
            this.savePatientToLocalstorage(resp.response);

            if (resp.response.cpr && resp.response.cpr != '') {
              // this.isRegisteredExternally = true;
              this.isRegisteredExternally = false;
              this.loadCurrentClinic();
              this.getExternalData(resp.response.cpr, resp.response);
            } else {
              this.isRegisteredExternally = false;

              this.userData.FirstName = resp.response.firstName;
              this.userData.LastName = resp.response.lastName;
              this.userData.MiddleName = resp.response.middleName;

              this.userData.MobilePhone = resp.response.mobil;
              this.userData.PostNr = resp.response.post_nr;
              this.userData.Email = resp.response.email;
              this.userData.CaseNumber = resp.response.case_nr;

              this.loadCurrentClinic();
            }
          } else {
            self.userFound = false;
            // this.notification = {message: "ERROR", type: "error"}
          }
        },
        err => {
          // this.notification = {message: "ERROR", type: "error"}
          self.userFound = false;
        }
      );

    // this.http.get(`https://fbooking.adevo.io:3000/api/all-clinics`)
    this.http.get(`${this.u.getApi()}/api/all-clinics`)

      // .map((res: any) => {
      //     console.log('shit', res);
      //     return res.json();
      // })
      .subscribe((res: any) => {
        if (res.status == 200) {
          var clinics = res.response;
          self.allClinics = clinics.filter(el => el.danica === 1);
          localStorage.setItem("allClinics", JSON.stringify(self.allClinics));
        } else {
          console.error("Error", res);
        }

      });

    console.log('TESTING CLINIC STORAGE', JSON.parse(localStorage.getItem('currentClinic')));

  }

  getExternalData(cpr, internal) {
    this.http.get(this.u.getApi() + '/digiapi/get-patient-data/' + cpr)
      // .map((res: any) => res.json())
      .subscribe(
        (params: any) => {
          if ('undefined' !== typeof params.response.DigifysPatientData) {
            console.log("received patient Data- ", params.response.DigifysPatientData)

            this.userData = params.response.DigifysPatientData;
            this.userData.PostNr = internal.post_nr;
            this.userData.CaseNumber = internal.case_nr;
            console.log("received patient userData- ", this.userData);
          }
        }
      );
  }

  finishBooking() {
    this._patient = JSON.parse(localStorage.getItem("currentPatient"));


    let self = this;
    let [from, to] = this.selectedTime["_id"].split("_");
    let bodyParts = this.params.bodyParts
    if (!bodyParts || bodyParts == "")
      bodyParts = "andet";

    let body = {
      from: from,
      to: to,
      therapist: this.therapist,
      clinic: this._patient.clinicID,
      bodyParts: bodyParts,
      // patient: this.patientId,
      case_nr: this._patient.case_nr || 1,
      complimentaId: this._patient.complimentaId,
      // complimentaId: 1233,
      cpr: this._patient.CPR,
    }
    console.log('this._currentPatient =>', this._patient);
    console.log('this._currentPatient.CPR =>', this._patient.CPR);

    if (body) {
      localStorage.setItem("currentBooking", JSON.stringify(body));
    }

    console.log(this.selectedTime);
    console.log(body);
    console.log("=================================");
    // return;

    this.http.post(`${this.API}/api/finish-booking`, body)
      .map((res: any) => res)
      .subscribe(res => {
        console.log("finish-booking response - ", res.response);
        if (res.status == 200) {
          // self.notification = {message: "YAAAAY!", type: "success"};

          self.navigateToSummary();
        } else {
          // self.notification = {message: "Error booking time", type: "error"};
          self.notification = {message: "", type: ""};
          self.bookingFailed = true;
          console.error("Error", res);
        }
      });
  }

  navigateFurther() {
    let self = this;
    localStorage.setItem('patientLoggedIn', JSON.stringify(true))
    // this.params = this.activatedRoute.snapshot.queryParams;
    // this.cpr = this.params.cpr;
    let patientData = JSON.parse(localStorage.getItem("currentPatient"));
    if (patientData) {
      patientData.CPR = this.userData.CPR;
      this.savePatientToLocalstorage(patientData);
      console.log('navigateFurther, settings currentPatientData', patientData);
    }


    let navigationExtras: NavigationExtras = {
      queryParams: {
        'cpr': this.params.cpr,
        'clinic-id': this.params['clinic-id'],
        'bodyParts': this.params['bodyParts'],
        'token': this.params['token']
      }
    };


    let postData = {
      "FirstName": this.userData.FirstName,
      "MiddleName": this.userData.MiddleName,
      "LastName": this.userData.LastName,
      "Email": this.userData.Email,
      "CPR": this.userData.CPR.replace('-', ''),
      "MobilePhoneNumber": this.userData.MobilePhone,
      complimentaId: this.userData.complimentaId
      // "clinic": "0000c0a8c102000007d713000000012066873f652bf974e5" // is this being used at all?
    };


    console.log('before update patient data')
    this.updatePatientData(postData)
      .map((res: any) => res)
      .subscribe(res => {
        console.log("finish-bookingresponse - ", res.response);
        if (res.status == 200) {
          // self.patientSuccess()
          self.finishBooking();
        } else {
          self.notification = {message: "ERROR", type: "error"};
          console.error("Error", res);
        }
      });
    ;


    // this.router.navigate(['/body-parts'], navigationExtras);
    // this.navigateToSummary();
  }

  navigateToSummary() {
    this._clinic = JSON.parse(localStorage.getItem("currentClinic"));
    this._currentPatient = JSON.parse(localStorage.getItem("currentPatient"));

    console.log("Navigate to summary!");
    let patient = {
      firstName: this._currentPatient.FirstName || this._currentPatient.firstName,
      lastName: this._currentPatient.LastName || this._currentPatient.lastName,
      Email: this._currentPatient.Email || this._currentPatient.email,
      clinicName: (this._clinic && this._clinic.clinicName) || "FYSIQ",
      bodyParts: this.params.bodyParts,
      token: this.params.token
    };
    this.router.navigate(['/success-patient'], {queryParams: patient});

  }

  openSamtykkeModal() {
    $('#open-samtykke-modal').show();
  }

  closeSamtykkeModal() {
    $('#open-samtykke-modal').hide();
  }

  openPoliticModal() {
    $('#open-politic-modal').show();
  }

  closePoliticModal() {
    $('#open-politic-modal').hide();
  }

  openEmailModal() {
    $('#open-email-modal').show();
  }

  closeEmailModal() {
    $('#open-email-modal').hide();
  }

  openSmsModal() {
    $('#open-sms-modal').show();
  }

  closeSmsModal() {
    $('#open-sms-modal').hide();
  }

  loadCurrentClinic() {
    console.log("load curent clinic token: ", this.token);
    this.http.get(this.u.getApi() + '/clinics/clinic/' + this.token)
      .subscribe((resp: any) => {
        console.log("fetched current clinic: ", resp.response);
        localStorage.setItem("currentClinic", JSON.stringify(resp.response));
      });
  }

  updatePatientToLocalStorage(resp) {
    const patientData = JSON.parse(localStorage.getItem("currentPatient"));
    if (patientData) {
      patientData.complimentaId = resp.cID;
      this.userData.complimentaId = resp.cID;
      this.savePatientToLocalstorage(patientData);
    }
  }

  savePatientToLocalstorage(data) {
    this._currentPatient = data;
    localStorage.setItem("currentPatient", JSON.stringify(data));
  }


  updatePatientData(postData) {
    let postData2 = postData;
    postData2['post_nr'] = this.userData.PostNr;
    postData2['token'] = this.token;
    postData2['cpr'] = this.userData.CPR;

    console.log('sutu', postData2);

    return this.http.post(this.u.getApi() + '/patient/update-patient', postData2);

  }

  moveForward() {
    this.loading = true;
    this.notification = {message: "Indlæser", type: "loading"};
    // if (this.isRegisteredExternally) { // always false
    //   this.updateProfile().subscribe(
    //     (resp: any) => {
    //       if (resp.status === 200) {
    //         this.updatePatientToLocalStorage(resp)
    //         this.navigateFurther();
    //       } else {
    //         console.log("update else");
    //         this.loading = false;
    //         this.notification = {message: "CPR Eksisterer allerede", type: "error"};
    //       }
    //     },
    //     err => {
    //       console.log("update profile else 2");
    //       this.loading = false;
    //       this.notification = {message: "CPR Eksisterer allerede", type: "error"};
    //     }
    //   );
    // } else {
    this.createProfile().subscribe(
      (resp: any) => {

        if (resp.status === 200) {
          this.updatePatientToLocalStorage(resp)
          this.navigateFurther(); // user exists or created succesflly, go to booking
        } else {
          console.log("create profile else");
          this.loading = false;
          this.notification = {message: "CPR Eksisterer allerede", type: "error"};
        }
      },
      err => {
        console.log("create profile else 2");
        this.loading = false;
        this.notification = {message: "CPR Eksisterer allerede", type: "error"};
      }
    );
    // }
  }

  moveBack() {
    let queryParams = this.params;
    console.log("this params - ", queryParams);
    this.router.navigate(['/book-time'], {queryParams});
  }

  updateProfile() {
    const postData = this.getPostData();
    const url = this.u.getApi() + "/api/update";
    return this.http.post(url, postData);
  }

  createProfile() {
    const patientData = JSON.parse(localStorage.getItem("currentPatient"));
    const clinic = JSON.parse(localStorage.getItem("currentClinic"));
    const postData = this.getPostData();
    postData['clinic'] = patientData.clinicID;
    postData['therapist'] = this.therapist;

    this.updatePatientData(postData).subscribe((resp) => {
    });

    const url = this.u.getApi() + "/api/register";
    return this.http.post(url, postData);
  }

  getPostData() {

    return {
      // "PhysiotherapistId": 7776,
      "FirstName": this.userData.FirstName,
      "MiddleName": this.userData.MiddleName || "-",
      "LastName": this.userData.LastName,
      "Email": this.userData.Email,
      "CPR": this.userData.CPR.replace("-", ""),
      "MobilePhoneNumber": this.userData.MobilePhone,
    };
  }
}
