import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from '../auth/service';
import { UtilsService } from '../../utils.service';

@Component({
	selector: 'app-booking-oversigt',
	templateUrl: './booking-oversigt.component.html',
	styleUrls: ['./booking-oversigt.component.scss']
})

export class BookingOversigtComponent implements OnInit {
	API = this.u.getApi();

	// User, patients, clinics
	currentUser = localStorage.getItem('currentUser');
	allClinics = JSON.parse(localStorage.getItem('allClinics'));
	allPatients = [];
	patients = [];

	// Table variables
	patientProperties = ["case_nr", "cpr", "mobil", "email", "post_nr", "clinicName", "message_sent"];
	messageSentStatus = {"true":"Link Afsendt", "false": "Booking oprettet"};
	tableHeaders = ["Skades Nr.", "CPR", "Mobil", "Email", "Post Nr.",  "Klinik", "Status", "Gensend"];
	page = 0;
	pageSize = 5;
	nextMessageTimeout = 0;

	// action variables
	searchString: String;
	loading = false;
	notification = {message: "", type: ""};

	constructor(
		private http: HttpClient,
		public u: UtilsService
	) {}

	ngOnInit() {
		this.getBookings();
	}

	getBookings(){
		this.http.get(`${this.API}/users/bookings`)
		.subscribe((resp: any) => {

			if (resp.status === 200) {

				resp.response.map(el => {

					if (el.message_sent_date) {
						let msgDate = new Date(el.message_sent_date);

						msgDate.setDate(msgDate.getDate() + this.nextMessageTimeout)
						el.allow_resend =  msgDate < new Date();
					} else {
						el.allow_resend = true;
					}
				});
				
				this.allPatients = resp.response;
				this.patients = this.paginate(resp.response, this.pageSize);
			}
		});
	}

	paginate (array, pageSize) {
		let pageCount = ~~((array.length + pageSize - 1) / pageSize),
			paginatedData = [];

		for (let pageNumber = 0; pageNumber < pageCount; pageNumber++) {
			let pageData = array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);

			paginatedData.push(pageData)
		}

		return paginatedData;
	}

	changePage(index){
		this.page = index;
	}

	onInuptKeyup(event) {

		if (event.key === "Enter") {
			this.filterPatients()
		}
	}
	filterPatients(){
		let str = this.searchString.toLowerCase(),
			patients = this.allPatients.filter(el => {
			const values = Object.keys(el).map(key => el[key]);

			for (let i = 0; i < values.length; i++){
				let val = values[i],
					found = typeof val == "string" && val.toLowerCase().indexOf(str) !== -1;

				if (found) {
					return true
				}
			}

			return false;
		});

		this.patients = this.paginate(patients, this.pageSize);
	}

	resendMail(el, patient) {

		if (!el.target.closest("td").classList.contains("disabled-component")) {
			this.loading = true;
		
			let self = this,
				clinic = this.allClinics.filter(el => el.id === patient.clinicID);

			if (!clinic.length) {
				this.loading = false;
				self.notification = {message: "Message NOT re-sent!", type: "error"}
			}

			let body = {
				token: patient.token,
				message_sent: true,
				clinic: clinic[0]
			}

			const response = this.http.post(this.u.getApi()  + '/api/resend-success-message', body)
			.subscribe(
				(resp: any) => {

					if (resp.status === 200) {
						self.notification = {message: "Beskeden er gensendt", type: "success"};
						self.changeResendStatus(patient);
					} else {
						self.notification = {message: "Message NOT sent!", type: "error"};
					}
				},
				(err: any) => {self.notification = {message: "Message NOT sent!", type: "error"};},
				() => {
					setTimeout(()=>{
						self.notification = {message: "", type: ""};
						self.loading = false;
					}, 1000);
				}
			)
		}
	}
	
	changeResendStatus(patient){
		let patients = this.patients;

		patients[this.page].map(el=> {

			if (el["_id"] === patient["_id"]) {
				el.allow_resend = false;
			}
		});

		this.patients = patients;
	}
}