import {Component, OnInit, OnChanges, NgZone, NgModule} from '@angular/core';
import {Injectable} from '@angular/core';
import {Router, ActivatedRoute, Params, NavigationExtras} from '@angular/router';
import {Http} from '@angular/http';
import {UtilsService} from '../../utils.service';

import {BrowserModule} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';


// import * as $ from 'jquery';
import * as _ from 'underscore';
// const _ = require('underscore');

@Component({
    selector: 'app-success-patient',
    templateUrl: './success-patient.component.html',
    styleUrls: ['./success-patient.component.scss']
})

export class SuccessPatientComponent implements OnInit {

    API = this.u.getApi();
    params: any;

    _patient = JSON.parse(localStorage.getItem("currentPatient"));
    _clinic = JSON.parse(localStorage.getItem("currentClinic"));
    selectedTime = JSON.parse(localStorage.getItem("selectedTime"));
    therapist = JSON.parse(localStorage.getItem("therapist"));


    constructor(
      private http: HttpClient,
      public u: UtilsService,
      private route: ActivatedRoute,
      private router: Router) {}

    ngOnInit() {
      this.params = this.route.snapshot.queryParams;

      // this.finishBooking();

      let params = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
      this.makeTokenInactive(params.token);


    }

    makeTokenInactive(token) {
        this.http.post(this.u.getApi() + '/api/invalidate-token/', {
            active: false,
            token
        })
        .map((res: any) => res)
        .subscribe(res => {
          console.log("response - ", res)
          if (res.status === 200){
            this.patientSuccess();
          } else {
            console.error("token invalidate error")
          }
        });
    }

    navigateFurther(e) {
        e.preventDefault();

        this.http.post(this.API + '/api/test', this.params)
        .subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log("Error occured");
          }
        );
        let navigationExtras: NavigationExtras = {queryParams: {
          'token': this.params['token']
        }};

        this.router.navigate(['/profil'], navigationExtras);
    }


    patientSuccess() {
      let params = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));

      const myObserver = {
          next: resp => {
              if (resp.status === 200) {
                  console.log("patient success! resp - ", resp.response);
              } else {
                  console.log('patien succes failure. res - ', resp.response);
              }
          },
      };


      this.params = this.route.snapshot.queryParams;
      let currentBooking = JSON.parse(localStorage.getItem("currentBooking"));
      params.CPR = this["_patient"].CPR;
      params.Email = this["_patient"].email;
      params.phone = this["_patient"].mobil;
      params.CaseNr = this["_patient"].case_nr;
      params.clinic = this._clinic;
      params.from = currentBooking.from;
      params.to = currentBooking.to;
      // const response = this.http.post(this.u.getApi()  + '/api/patient-success', this.params)
      const response = this.http.post(this.u.getApi()  + '/api/patient-success', params)
      .subscribe(myObserver);

      console.log('response', response);
    }
}
