import { Component, OnInit, NgZone, ElementRef, Output, EventEmitter, Input  } from '@angular/core';
import {Location} from '@angular/common';

import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';

import { Http } from '@angular/http';
import * as $ from 'jquery';

import { UtilsService } from '../../utils.service';
import {AuthenticationService} from "../auth/service";


declare var require: any;
const _ = require('underscore');

@Component({
    selector: 'app-body-parts',
    templateUrl: './body-parts.component.html',
    styleUrls: ['./body-parts.component.scss']
})

export class BodyPartsComponent implements OnInit {
    constructor(
      private activatedRoute: ActivatedRoute,
      public u: UtilsService,
      private router: Router,
      private http: HttpClient,
      private authService: AuthenticationService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if ('undefined' !== typeof params.token) {
                this.token = params.token; // Print the parameter to the console.
            }
        });
      }

    body = "assets/img/body.svg";
    toggledPart = [];
    bodyParts = [];
    selectedGroupObj = {"name": "", "values" : []};
    selectedGroupObjSVG = {"name": "", "values" : []};
    params: any;
    token : string;
    userFound: boolean;

    allClinics: any;

    isRegisteredExternally: boolean = false;
    _currentPatient : any;


    @Output() bodyPartSelected: EventEmitter<any> = new EventEmitter<any>();

    groups = [
        {"name" : "body-head",
            "values" : ["body-neck", "body-head"],
          code:0 },
        {"name" :  "body-hands",
            "values" : ["body-hands", "body-right-hand", "body-left-hand"],
          code: 2},
        {"name" :  "body-shoulders",
            "values" : ["body-shoulders","body-shoulder-right","body-shoulder-left"],
          code: 2 },
        {"name" : "body-waist",
            "values" : ["body-waist"],
          code: 3 },
        {"name" : "body-knee",
            "values" : ["body-knee","body-lower-waist-right","body-lower-waist-left","body-knee-right","body-knee-left", ],
          code: 4 },
        {"name" : "body-legs",
            "values" : ["body-lower-knee","body-lower-knee-right", "body-lower-knee-left", "body-feet-right","body-feet-left"],
          code: 38 },
        {"name" : "body-back",
            "values" : ["body-back"],
          code: 1 },
        {"name" : "andet",
            "values" : ["andet"],
          code: 6 }
    ];

    ngOnInit() {
        var self = this;
        self.authService.logout();

        self.selectBodyParts();

        this.params = this.activatedRoute.snapshot.queryParams;

        console.log(this.params.bodyParts);
        if (this.params.bodyParts){
          this.toggledPart = this.params.bodyParts;
        }
        this.getFlowData();

    }
    getFlowData(){
      let self = this;
      this.http.get(this.u.getApi() + '/api/active-patient/' + this.token)
          .subscribe(
              (resp: any) => {
                  if (resp.status === 200 && resp.response) {
                      self.userFound = true;
                      this.savePatientToLocalstorage(resp.response);

                      if (resp.response.cpr && resp.response.cpr != '') {
                        // this.isRegisteredExternally = true;
                          this.isRegisteredExternally = false;
                          this.loadCurrentClinic();
                          this.getExternalData(resp.response.cpr, resp.response);
                      } else {
                          this.isRegisteredExternally = false;
                          //
                          // this.userData.FirstName = resp.response.firstName;
                          // this.userData.LastName = resp.response.lastName;
                          // this.userData.MiddleName = resp.response.middleName;
                          //
                          // this.userData.MobilePhone = resp.response.mobil;
                          // this.userData.PostNr = resp.response.post_nr;
                          // this.userData.Email = resp.response.email;
                          // this.userData.CaseNumber = resp.response.case_nr;

                          this.loadCurrentClinic();
                      }
                  } else {
                      self.userFound = false;
                      // this.notification = {message: "ERROR", type: "error"}
                  }
              },
              err => {
                  // this.notification = {message: "ERROR", type: "error"}
                  self.userFound = false;
              }
          );

      // this.http.get(`https://fbooking.adevo.io:3000/api/all-clinics`)
      this.http.get(`${this.u.getApi()}/api/all-clinics`)

          // .map((res: any) => {
          //     console.log('shit', res);
          //     return res.json();
          // })
          .subscribe((res: any) => {
              if (res.status == 200) {
                  var clinics = res.response;
                  self.allClinics = clinics.filter(el => el.danica === 1);
                  localStorage.setItem("allClinics", JSON.stringify(self.allClinics));
              } else {
                  console.error("Error", res);
              }

          });
    }
    savePatientToLocalstorage(data) {
        this._currentPatient = data;
        localStorage.setItem("currentPatient", JSON.stringify(data));
    }

    loadCurrentClinic() {
        console.log("load curent clinic token: ",this.token);
        this.http.get(this.u.getApi() + '/clinics/clinic/' + this.token)
            .subscribe((resp: any) => {
                console.log("fetched current clinic: ",resp.response);
                localStorage.setItem("currentClinic", JSON.stringify(resp.response));
            });
    }
    getExternalData(cpr, internal) {
        this.http.get(this.u.getApi() + '/digiapi/get-patient-data/' + cpr)
            // .map((res: any) => res.json())
            .subscribe(
                (params: any) => {
                    if ('undefined' !== typeof params.response.DigifysPatientData) {
                        console.log("received patient Data- ", params.response.DigifysPatientData)
                        //
                        // this.userData = params.response.DigifysPatientData;
                        // this.userData.PostNr = internal.post_nr;
                        // this.userData.CaseNumber = internal.case_nr;
                        // console.log("received patient userData- ", this.userData);
                    }
                }
            );
    }

    resetToggledParts() {
        this.toggledPart = [];
    }

    outputSelected(): void {
        var self = this;

        this.bodyPartSelected.emit(self.toggledPart);

        console.log(self.toggledPart);
    }

    markBodyParts(event: any) {
        this.toggledPart = [];
        var self = this,
            val = event.target.value;

        self.groups.map((group) => {
            group.values.map((value) => {

                if (value == val) {
                    self.selectedGroupObj = group;
                }
            })
        });

        if (event.target.checked) {
            self.toggledPart = [self.selectedGroupObj.name];
        } else {
            self.toggledPart = [];
        }
    }

    createGroups(parts: any) {
        var self = this;

        self.groups.map((group) => {
            group.values.map((value) => {
                self.bodyParts.map((part) => {

                    if (part.index == value) {
                        _.extend(part, {group: group.name});
                    }
                });
            });

        });
    }

    markBodyPartsSVG(event: any) {
        var self = this,
            id = event.target.id;

        self.groups.map((group) => {
            group.values.map((value) => {
                if (value == id) {
                    self.selectedGroupObjSVG = group;
                }
            })
        });

        $(".body-part-chooser ." + id + "").prop( "checked", true);

        var found = _.find(self.toggledPart, function(part) {
                        return part == self.selectedGroupObjSVG.name;
                    });

        if (found) {
            self.toggledPart = _.reject(self.toggledPart, function(part) {
                                return part == self.selectedGroupObjSVG.name;
                            });
        } else {
            // self.toggledPart = _.union(self.toggledPart, [self.selectedGroupObjSVG.name]);
            self.toggledPart = [self.selectedGroupObjSVG.name];
        }
    }

    navigateToProfile() {
      // this.params = this.activatedRoute.snapshot.queryParams;
      // this.cpr = this.params.cpr;

      let bodyCodes = [];
      this.groups.map(group => {
        if (this.toggledPart.includes(group.name) && !bodyCodes.includes(group.code)){
          bodyCodes.push(group.code) ;
        }
      });

      let navigationExtras: NavigationExtras = {queryParams: {
        'cpr': this.params.cpr,
        'clinic-id': this.params['clinic-id'],
        "bodyParts": this.toggledPart,
        'token': this.params['token']
      }};

      this.router.navigate(['/profil'], navigationExtras);
    }

    navigateToBookTime() {
      // this.params = this.activatedRoute.snapshot.queryParams;
      // this.cpr = this.params.cpr;
      let bodyCodes = [];
      this.groups.map(group => {
        if (this.toggledPart.includes(group.name) && !bodyCodes.includes(group.code)){
          bodyCodes.push(group.code) ;
        }
      });

      console.log("toggledparts - ", this.toggledPart);
      console.log("codes - ", bodyCodes);

      let params = {
            "cpr": this.params.cpr,
            "clinic-id": this.params['clinic-id'],
            "bodyParts": this.toggledPart,
            'token': this.params['token']
            // "bodyParts": bodyCodes
      };

      let navigationExtras: NavigationExtras = {queryParams: params};

      this.router.navigate(['/book-time'], navigationExtras);
    }

    selectBodyParts() {
        let self = this;
        let parts = [
            {
                "_id": "5aabb4e558edf3000fe8266b",
                "name": "Hofte",
                "index": "body-waist",
                "isSingle": true,
                "__v": 0
            },
            {
                "_id": "5aabb4e558edf3000fe8266a",
                "name": "Skulder",
                "index": "body-shoulders",
                "isSingle": false,
                "left": "body-shoulder-left",
                "right": "body-shoulder-right",
                "__v": 0
            },
            {
                "_id": "5aabb4e558edf3000fe8266c",
                "name": "Knæ",
                "index": "body-knee",
                "isSingle": false,
                "left": "body-knee-left",
                "right": "body-knee-right",
                "__v": 0
            },
            {
                "_id": "5aabb4e558edf3000fe8266d",
                "name": "Ryg / Torso",
                "index": "body-back",
                "isSingle": true,
                "__v": 0
            },
            {
                "_id": "5aabb4e558edf3000fe82669",
                "name": "Nakke",
                "index": "body-neck",
                "isSingle": true,
                "__v": 0
            },
            {
                "_id": "5aabb4e558edf3000fe8266e",
                "name": "Torso",
                "index": "body-back",
                "isSingle": true,
                "__v": 0
            },
            {
                "_id": "5aabb4e558edf3000fe8266f",
                "name": "Ankel/Fod",
                "index": "body-lower-knee",
                "isSingle": false,
                "left": "body-lower-knee-left",
                "right": "body-lower-knee-right",
                "__v": 0
            }
            ];
        parts.push({"index": "andet","isSingle": true,"name": "Andet (flere tilstødende regioner)","_id": "59c1286849cb1a000f9a8ecv" ,__v: 0});
        parts.push({"index": "body-hands","isSingle": true,"name": "Hænder","_id": "59c1286849cb1a000f9a8ecs" ,__v: 0});
        parts.push({"index": "body-head","isSingle": true,"name": "Hoved","_id": "59c1286849cb1a000f9a8ecz" ,__v: 0});

        parts = [];
        parts.push({"index": "body-head","isSingle": true,"name": "Nakke/Hoved","_id": "59c1286849cb1a000f9a8ecz" ,__v: 0});
        parts.push({"index": "body-hands","isSingle": true,"name": "Hænder/Albue","_id": "59c1286849cb1a000f9a8ecs" ,__v: 0});
        parts.push({"index": "body-shoulders","isSingle": false,"name": "Skulder","_id": "5aabb4e558edf3000fe8266a" ,__v: 0, "left": "body-shoulder-left","right": "body-shoulder-right"});
        parts.push({"index": "body-back","isSingle": true,"name": "Ryg/Torso","_id": "5aabb4e558edf3000fe8266d" ,__v: 0});
        parts.push({"index": "body-waist","isSingle": true,"name": "Hofte","_id": "5aabb4e558edf3000fe8266b" ,__v: 0});
        parts.push({"index": "body-knee","isSingle": false,"name": "Knæ","_id": "5aabb4e558edf3000fe8266c" ,__v: 0, "left": "body-knee-left","right": "body-knee-right"});
        parts.push({"index": "body-lower-knee","isSingle": false,"name": "Ankel/Fod","_id": "5aabb4e558edf3000fe8266f" ,__v: 0, "left": "body-lower-knee-left","right": "body-lower-knee-right"});
        parts.push({"index": "andet","isSingle": true,"name": "Andet (flere tilstødende regioner)","_id": "0" ,__v: 0});


        // res.response.push({index: "body-hands",isSingle: true,name: "Hænder",_id: "59c1286849cb1a000f9a8ecs" ,_v: 0});
        // res.response.push({index: "body-head",isSingle: true,name: "Hoved",_id: "59c1286849cb1a000f9a8ecz" ,_v: 0});

        // self.bodyParts = res.response;
        self.bodyParts = parts;
        if(self.bodyParts) {
            self.createGroups(self.bodyParts);
        }

    }
}
