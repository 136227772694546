import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { MapComponent } from './components/map/map.component';
import { HttpModule } from '@angular/http';
import { UtilsService} from './utils.service';
import { SogComponent } from './components/sog/sog.component';
import { SearchComponent } from './components/search/search.component';
import { ForlobComponent } from './components/forlob/forlob.component';
import { SuccessComponent } from './components/success/success.component';
import { SogProfilComponent } from './components/sog-profil/sog-profil.component';
import { BookingOversigtComponent } from './components/booking-oversigt/booking-oversigt.component';
import { ProfilComponent } from './components/profil/profil.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BodyPartsComponent } from './components/body-parts/body-parts.component';
import { FormsModule } from '@angular/forms';
import { BookTimeComponent } from './components/book-time/book-time.component';
import { AuthenticationService } from './components/auth/service';
import { SharedService } from './components/auth/sharedService';
import { MyDatePickerModule } from 'mydatepicker';
import { SuccessPatientComponent } from './components/success-patient/success-patient.component';
import { customValidatorDirective } from './components/profil/customValidator';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoMessagesComponent } from './components/info-messages/info-messages.component';
import {JwtModule} from '@auth0/angular-jwt';
import {AuthGuard} from './components/auth/authguard';
import { AddTokenInterceptor } from './components/auth/addTokenInterceptor';
import {LogoutComponent} from "./components/logout/logout.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    MapComponent,
    SogComponent,
    SearchComponent,
    ForlobComponent,
    SuccessComponent,
    SogProfilComponent,
    BookingOversigtComponent,
    ProfilComponent,
    BodyPartsComponent,
    BookTimeComponent,
    SuccessPatientComponent,
    InfoMessagesComponent,
    LogoutComponent,
    customValidatorDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    FormsModule,
    // HttpModule,
    HttpClientModule,
    MyDatePickerModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        // blacklistedRoutes: ['example.com/examplebadroute/'],
        authScheme: 'Bearer '
      }
    })
  ],
  providers: [
    UtilsService,
    AuthenticationService,
    SharedService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem('currentUser.token');
}
