import {Component, OnInit, Renderer, OnDestroy, ViewChild, OnChanges, NgZone, NgModule, AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute, Params, NavigationExtras} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})

export class SearchComponent implements OnInit {

  constructor(private renderer: Renderer, private router: Router) { }

  ngOnInit() {
    console.log('tipa searching stuff');
  }


  search() {
    return [
      {id: 2, name: 'apsis'},
      {id: 3, name: 'zirgs'},
      {id: 4, name: 'ferrari'},
      {id: 5, name: 'volvo'}
    ];
  }


}


