import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from 'environments/environment';

declare var require: any;
const _ = require('underscore');

@Injectable()
export class UtilsService {

    constructor( private http: HttpClient) {
    }

    getApi() {

        return environment.appUrl;
    }

    getFBookingAPI() {
        return 'https://fbooking.adevo.io:3000';
    }

    getBiApi() {
        return "https://bi.fysio.adevo.io/api"
    }

    getBiData()
    {
        return '{\n' +
            '    "status": 200,\n' +
            '    "response": [\n' +
            '        {\n' +
            '            "id": "0000c0a8c102000007d702000000011c178b806b7693a879",\n' +
            '            "name": "FYSIQ Sundby",\n' +
            '            "address": "Engvej 171",\n' +
            '            "post_code": "2300",\n' +
            '            "region_id": "1",\n' +
            '            "region": "Hovedstaden",\n' +
            '            "phone": "+45 3252 3562",\n' +
            '            "email": null,\n' +
            '            "cvr_number": "70028611",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "55.645031",\n' +
            '            "longitude": "12.637061",\n' +
            '            "city": "København S",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": null,\n' +
            '            "historic_bookings_count": 0\n' +
            '        },\n' +
            '        {\n' +
            '            "id": "0000c0a8c102000007d713000000012066873f652bf974e5",\n' +
            '            "name": "FYSIQ Kastrup",\n' +
            '            "address": "Kamillevej 4",\n' +
            '            "post_code": "2770",\n' +
            '            "region_id": "1",\n' +
            '            "region": "Hovedstaden",\n' +
            '            "phone": "+45 3252 3563",\n' +
            '            "email": "kaastrup@test.com",\n' +
            '            "cvr_number": "70028611",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "55.6327598",\n' +
            '            "longitude": "12.62183529999993",\n' +
            '            "city": "Kastrup",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": null,\n' +
            '            "historic_bookings_count": 0\n' +
            '        },\n' +
            '        {\n' +
            '            "id": "0000c0a8c102000007d402000000012e572ca89999062acf",\n' +
            '            "name": "FYSIQ Ørestad",\n' +
            '            "address": "Center Boulevard 4",\n' +
            '            "post_code": "2300",\n' +
            '            "region_id": "1",\n' +
            '            "region": "Hovedstaden",\n' +
            '            "phone": "+45 3252 3564",\n' +
            '            "email": null,\n' +
            '            "cvr_number": "70028611",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "55.639383",\n' +
            '            "longitude": "12.574779000000035",\n' +
            '            "city": "København S",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": null,\n' +
            '            "historic_bookings_count": 0\n' +
            '        },\n' +
            '        {\n' +
            '            "id": "00000a00ca08000007d50d0000000165181c063db9c33d64",\n' +
            '            "name": "FYSIQ Nørreport",\n' +
            '            "address": "Nørre Voldgade 11",\n' +
            '            "post_code": "1358",\n' +
            '            "region_id": "1",\n' +
            '            "region": "Hovedstaden",\n' +
            '            "phone": "+45 33133343",\n' +
            '            "email": null,\n' +
            '            "cvr_number": "70028611",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "55.683130",\n' +
            '            "longitude": "12.570780",\n' +
            '            "city": "København K",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": 0,\n' +
            '            "historic_bookings_count": 0\n' +
            '        },\n' +
            '        {\n' +
            '            "id": "0000c0a8c102000007d732000000011e963db85ed6024ea4",\n' +
            '            "name": "FYSIQ",\n' +
            '            "address": "Løjtegårdsvej 157",\n' +
            '            "post_code": "2770",\n' +
            '            "region_id": null,\n' +
            '            "region": null,\n' +
            '            "phone": "+45 3252 3560",\n' +
            '            "email": null,\n' +
            '            "cvr_number": "",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "",\n' +
            '            "longitude": "",\n' +
            '            "city": "Kastrup",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": 0,\n' +
            '            "historic_bookings_count": 0\n' +
            '        },\n' +
            '        {\n' +
            '            "id": "0000c0a8c102000007d704000000011cb1d421de7a0d58f5",\n' +
            '            "name": "FYSIQ Tårnby",\n' +
            '            "address": "Løjtegårdsvej 157",\n' +
            '            "post_code": "2770",\n' +
            '            "region_id": null,\n' +
            '            "region": null,\n' +
            '            "phone": "+45 3252 3560",\n' +
            '            "email": null,\n' +
            '            "cvr_number": "70028611",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "",\n' +
            '            "longitude": "",\n' +
            '            "city": "Kastrup",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": 0,\n' +
            '            "historic_bookings_count": 0\n' +
            '        },\n' +
            '        {\n' +
            '            "id": "0000c0a8c102000007d708000000011dbaa026bd99522c81",\n' +
            '            "name": "FYSIQ Dragør",\n' +
            '            "address": "Bymandsgade 2 ",\n' +
            '            "post_code": "2791",\n' +
            '            "region_id": null,\n' +
            '            "region": null,\n' +
            '            "phone": "+45 3252 3561",\n' +
            '            "email": null,\n' +
            '            "cvr_number": "70028611",\n' +
            '            "super_clinic": 0,\n' +
            '            "latitude": "",\n' +
            '            "longitude": "",\n' +
            '            "city": "Dragør",\n' +
            '            "picture": "https://bi.fysio.adevo.io/img/building_placeholder.jpg",\n' +
            '            "bookings_grace_period": 0,\n' +
            '            "currently_active_days_threshold": 0,\n' +
            '            "historic_bookings_count": 0\n' +
            '        }\n' +
            '    ]\n' +
            '}';
    }

    getClinics() {


        // hardcoded
        var clinics = [
            {
                "_id": "0000c0a8c102000007d702000000011c178b806b7693a879",
                "name": "Fysiq Ørestaden",
                "address": "Center Boulevard 4	2300	København S",
                "post_code": 2300,
                "city": "København S",
                "website": "www.fysiq.dk",
                "latitude": 55.639383,
                "longitude": 12.574779000000035
            },
            {
                "_id": "0000c0a8c102000007d702000000011c178b806b7693a871",
                "name": "Fysiq Tårnby",
                "address": "Løjtegårdsvej 157	2770	Kastrup",
                "post_code": 2770,
                "city": "København Ø",
                "website": "www.fysiq.dk",
                "latitude": 55.6230813,
                "longitude": 12.605103099999951
            },
            {
                "_id": "0000c0a8c102000007d702000000011c178b806b7693a872",
                "name": "Fysiq Kastrup",
                "address": "Kamillevej 4	2770	Kastrup",
                "post_code": 21277000,
                "city": "København Ø",
                "website": "www.fysiq.dk",
                "latitude": 55.6327598,
                "longitude": 12.62183529999993
            },
            {
                "_id": "0000c0a8c102000007d702000000011c178b806b7693a873",
                "name": "Fysiq Engvej",
                "address": "Engvej 171	2300	København S	",
                "post_code": 2300,
                "city": "København Ø",
                "website": "www.fysiq.dk",
                "latitude": 55.6452384,
                "longitude": 12.637348100000054
            },
            {
                "_id": "0000c0a8c102000007d702000000011c178b806b7693a874",
                "name": "Fysiq Dragør",
                "address": "Bygmandsgade 2	2791	Dragør",
                "post_code": 2791,
                "city": "København Ø",
                "website": "www.fysiq.dk",
                "latitude": 55.5916674,
                "longitude": 12.670653600000037
            }
        ]

        return clinics;
    }



}
