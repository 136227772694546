import {Injectable} from '@angular/core';
import {Headers, Response, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {HttpClient} from "@angular/common/http";

import {UtilsService} from '../../utils.service';

declare var require: any;
const _ = require('underscore');

@Injectable()
export class AuthenticationService {

    _activeUser: any;
    _patientLoggedIn: any;
    get activeUser() {
        if (!this._activeUser) {
            this._activeUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        return this._activeUser;
    }

    set activeUser(value) {
        console.log('setting active user');
        this._activeUser = value;
    }

    get activeUserName() {
        const activeUser = this.activeUser;
        if (activeUser) {
            return `${activeUser.data.username}`;
        }
    }
            // this._patientLoggedIn = JSON.parse(localStorage.getItem('patientLoggedIn'));

    get patientLoggedIn() {
        if (!this._patientLoggedIn) {
            let patientLoggedIn = JSON.parse(localStorage.getItem('patientLoggedIn'));

            if (patientLoggedIn !== null) {
                return true;
            }
        }

        return false;
    }



    // API = "http://212.71.247.241:3000";
    // API = "http://127.0.0.1:3000";
    API = this.u.getApi();
    FBOOKING = this.u.getFBookingAPI();


    currentUser: any;
    allTherapists: any;
    allClinics = JSON.parse(localStorage.getItem('allClinics'));

    bookings = [];
    selectedBooking: string;
    currentBooking = [];
    currentBookingDate: Date;
    months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    bodyPartLabels = [
        {"key": "body-back", "label": "Torso"},
        {"key": "body-head", "label": "Hoved"},
        {"key": "body-legs", "label": "Ben"},
        {"key": "body-hands", "label": "Hænder"}
    ];

    constructor(private http: HttpClient, public u: UtilsService) {
    }

    // create() {
    //     let a = this.http.post(`${this.API}/users/authenticate`, {
    //         username: 'db2',
    //         password: '123'
    //     }, options)
    //         .map((r) => {
    //             const resp = r.json();
    //             if (resp.status == 200) {
    //                 // var response = resp.response;
    //                 console.log("RESPONSE. User profile from login", response);
    //                 // localStorage.setItem('currentUser', JSON.stringify({
    //                 //     data: response.data,
    //                 //     token: response.token,
    //                 //     _id: response._id,
    //                 //     userActive: true,
    //                 // lastBooking: response.lastBooking,
    //                 // }));
    //             } else {
    //                 console.error("Error in auth", resp);
    //             }
    //             this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //             return r;
    //         });
    //
    //     return '';
    // }

    login(data) {



        // let a = this.http.post(`${this.API}/users/authenticate`, {
        //     username: 'db2',
        //     password: '123'
        // }, options)
        //     .map((r) => {
        //         const resp = r.json();
        //         if (resp.status == 200) {
        //             // var response = resp.response;
        //             // console.log("RESPONSE. User profile from login", resp);
        //             // localStorage.setItem('currentUser', JSON.stringify({
        //             //     data: response.data,
        //             //     token: response.token,
        //             //     _id: response._id,
        //             //     userActive: true,
        //                 // lastBooking: response.lastBooking,
        //             // }));
        //         } else {
        //             console.error("Error in auth", resp);
        //         }
        //         this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        //         return r;
        //     });

        return this.currentUser;
    }

    getAllTherapists() {
        var self = this;

        var clinics_query = self.buildClinicsQuery();

        console.log("in select Therapists !!!!!!!!2", `${this.FBOOKING}/api/physiotherapists` + clinics_query);

        const response = this.http.get(`${this.FBOOKING}/api/physiotherapists` + clinics_query);

        return response.map((res: any) => {
            let result = res.json();
            if (result.status == 200) {
                console.log("in specify specialist component ts. selectherapists response ==>", result.response);
                self.allTherapists = result.response;
                // var offered = localStorage.getItem('lastTherapist').toLowerCase();
                //self.offeredSpecialist = self.clinicSpecialists.filter(el => el.id == offered);
                //self.isOfferedSpecialist = self.offeredSpecialist.length > 0 ;
                //self.saveTherapistsToStorage(res.response);
                localStorage.setItem("allTherapists", JSON.stringify(result.response));
                //  console.log("all therapists finished: ", localStorage.getItem("allTherapists"));
            } else {
                console.error("Error", result);
            }
        });
    }

    buildClinicsQuery() {
        var query = this.currentUser.data.clinicId;
        return "?clinics=" + query;
    }

    getAuthedBookings() {
        let self = this;

        console.log("get authed user's bookings after login", self.currentUser);
        let data = {
            client: self.currentUser.data.complimentaId,
            clinic: self.currentUser.data.clinicId,
            from: '2000-01-01 00:00:00',
            to: '2020-01-01 00:00:00'
        };

        // let options = self.buildAuthHeader();
        let options = {};

        const response = self.http.post(`${this.FBOOKING}/users/my-bookings`, JSON.stringify(data), options);

        return response.map((r: any) => {
            let res = r.json();
            if (res.status == 200) {

                res.response.map(el => {
                    _.extend(el, {"_id": el.booking});
                });
                self.bookings = res.response.filter(el => el.booking); // filter out offered_service and other non-booking-type objects
                var newElements = [];
                self.bookings.map(el => {

                    // if(typeof el.comment === 'object' && el.comment !== null){
                    //   el.comment = JSON.parse(el.comment);
                    // } else {
                    //   el.comment = {
                    //     behandling: '',
                    //     bodyparts: '',
                    //     insurance: '',
                    //     treatment: ''
                    //   };
                    // }
                    console.log("COMMENT =>", el.comment);

                    try {
                        let temp = JSON.parse(el.comment);
                        el.comment = temp;
                    } catch (e) {
                        // el.comment doesn't change here
                        el.comment = {
                            comment: el.comment,
                            behandling: '',
                            bodyparts: '',
                            insurance: '',
                            treatment: ''
                        };
                    }

                    console.log("COMMENT=>", el.comment)

                    if (el.comment.bodyparts.indexOf(',') > -1) {
                        // newElements = [];
                        // var part = el.comment.bodyparts.split(",");
                        //   part.map((elem) => {
                        //     self.bodyPartLabels.map((label) => {
                        //     if(elem.indexOf(label.key) && (newElements.indexOf(label.label) == -1))
                        //       newElements.push(label.label);
                        //     });
                        //   });
                        // el.comment.bodyparts = newElements.join(",");
                    } else {
                        self.bodyPartLabels.map((label) => {
                            if (el.comment.bodyparts == label.key)
                                el.comment.bodyparts = label.label;
                        });
                    }
                    el.therapistData = self.allTherapists.filter(el2 => el.therapist.toLowerCase() == el2.id.toLowerCase())[0];
                    // if(localStorage.getItem("allClinics"))
                    //   el.clinicName = self.allClinics.filter(el2 => "00000a00ca10000007d104000000016156990d7e4359a6bc" == el2.id )[0];
                    el.date = el.from.substring(8, 10) + ' ' + self.months[parseInt(el.from.substring(5, 7))] + ' ' + el.from.substring(0, 4);
                    el.dateHeader = el.from.substring(8, 10) + ' ' + self.months[parseInt(el.from.substring(5, 7))] + ' ' + el.from.substring(0, 4) + ' - ' + el.from.substring(11, 16);
                });
                localStorage.setItem("bookings", JSON.stringify(self.bookings));
                console.log("finished getAuthedBookings() ", localStorage.getItem("bookings"));

            } else {
                console.error("Error in getting bookings", res);
            }
        });

    }

    getAllClinics() {
        // return this.http.get(`${this.FBOOKING}/api/all-clinics`)
        return this.http.get(`${this.u.getApi()}/api/all-clinics`)
            .map((r: any) => {
                let res = r.json();
                if (res.status == 200) {
                    res.response.map(el => {
                        _.extend(el, {"_id": el.id});
                    });
                    localStorage.setItem("allClinics", JSON.stringify(res.response));
                    return res.response;
                } else {
                    console.error("Error", res);
                }
            });
    }
    //
    logout() {
        this.activeUser = null;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentPatient');
        localStorage.removeItem('currentClinic');
        localStorage.removeItem('lastTherapist');
        localStorage.removeItem('bookings');
        localStorage.removeItem('offerBooking');
        localStorage.removeItem('allClinics');
        localStorage.removeItem('allTherapists');
        localStorage.removeItem('fisiqClinics');
        localStorage.removeItem('patientLoggedIn');
    }

    isLoggedIn(): boolean {
        return Boolean(localStorage.getItem('currentUser'));
    }

    hasBookings(): boolean {
        let bookings = JSON.parse(localStorage.getItem("bookings"));
        console.log("bookings - ", bookings, "bookings ir []? - ", bookings === []);
        if (!Array.isArray(bookings) || bookings.length === 0) {
            return false
        }

        let cID = JSON.parse(localStorage.getItem("currentUser")).data.clinicId;
        let clinic = JSON.parse(localStorage.getItem("allClinics")).filter(el => el.id === cID)[0];

        console.log("--------");
        console.log(JSON.parse(localStorage.getItem("allClinics")));
        console.log(JSON.parse(localStorage.getItem("currentUser")));
        console.log("--------");
        let period = clinic.currently_active_days_threshold ? clinic.currently_active_days_threshold : 0;
        let minBookings = clinic.historic_bookings_count;

        let today = new Date();
        console.log("clinic - ", clinic, " period - ", period, " bookings min - ", minBookings);
        console.log("New date today: ", today);

        let recentBookings = bookings.filter(el => {
            let bDate = new Date(el.from.replace(" ", "T")); //fix for Safari date format
            var timeDiff = Math.abs(today.getTime() - bDate.getTime());
            var diffDays = Math.round(timeDiff / (1000 * 3600 * 24));
            return diffDays <= period;
        });

        console.log("recentBookings - ", recentBookings);

        if (recentBookings.length > 0 && recentBookings.length >= minBookings) {
            localStorage.setItem("offerBooking", "true");
        }

        return Boolean(recentBookings.length > 0 && recentBookings.length >= minBookings);
    }

    getToken() {
        var token = "";
        var data_json = localStorage.getItem("currentUser");
        if (data_json) {
            var data = JSON.parse(data_json);
            token = data.token;
        }

        return token;
    }

    buildAuthHeader() {
        let headers = new Headers({'Content-Type': 'application/json', 'Authorization': this.getToken(),});
        return new RequestOptions({headers: headers});
    }


}
