import { Component, OnInit, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { HttpClient } from "@angular/common/http";

import { IMyDpOptions, IMyDateModel} from 'mydatepicker';
import moment = require("moment");

import { AuthenticationService } from '../auth/service';
import { UtilsService } from '../../utils.service';

declare var require: any;
const _ = require('underscore');

@Component({
  selector: 'app-book-time',
  templateUrl: './book-time.component.html',
  styleUrls: ['./book-time.component.scss']
})

export class BookTimeComponent implements OnInit {
  API = this.u.getApi();
  notification = {message: "", type: ""};
  params: any;
  allClinics = JSON.parse(localStorage.getItem("allClinics"));
  _clinic = JSON.parse(localStorage.getItem("currentClinic"));
  _currentPatient = JSON.parse(localStorage.getItem("currentPatient"));
  therapist = ""
  selectedTherapist = "";
  patient = JSON.parse(localStorage.getItem("currentPatient")) || {};

  // Time variables
  allAvailableTimes = {};
  selectedDate = "";
  timeSplitInterval = 0;
  availableTimes = [];
  selectedTime = "";
  selDate = {};

  // Calendar options
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    inline: true,
    dayLabels: {su: 'Søn.', mo: 'Man.', tu: 'Tirs.', we: 'Ons.', th: 'Tors.', fr: 'Fre.', sa: 'Lør.'},
    monthLabels: { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Maj', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Okt', 11: 'Nov', 12: 'Dec' },
    sunHighlight: false,
    showTodayBtn: false,
    todayBtnTxt: 'I dag',
    markCurrentMonth: true,
    markDates: [],
    selectorWidth: "100%"
  };

  constructor(private _location: Location,
              private _ngZone: NgZone,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              public u: UtilsService,
              private authenticationService: AuthenticationService) { }


  ngOnInit() {
    let self = this;

    this.params = this.route.snapshot.queryParams;

    this.route.queryParams.subscribe((params: Params) => {
      self.selectedTherapist = params.t;

      self.getAvailableTimes();
    });
  }

  pad(n) {
      return n < 10 ? '0' + n : n;
  }

  getDateString(dateObj) {
    let year = dateObj.getFullYear(),
        month = this.pad(dateObj.getMonth() + 1),
        day = this.pad(dateObj.getDate()),
        hour = this.pad(dateObj.getHours()),
        min = this.pad(dateObj.getMinutes());

    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":59";
  }

  getAvailableTimes() {
    let self = this,
        date = new Date(),
        from = this.getDateString(date);

    date.setDate(date.getDate() + 30);

    let to = this.getDateString(date),
        bodyPart = this.params.bodyParts;

    bodyPart = Array.isArray(bodyPart) && bodyPart.length ? bodyPart[0] : bodyPart;

    let body = {
      from: from,
      to: to,
      therapist: this.therapist,
      clinic: this.patient.clinicID,
    }

    if (bodyPart) {
      body["bodyPart"] = bodyPart
    }

    self.http.post(`${this.API}/api/available-times`, body)
      .map((res: any) => res)
      .subscribe(res => {

          if (res.status == 200) {
              this.allAvailableTimes = res.response;

              self.setAvailableTimes();
          }
          else {
              console.error("Error", res);
          }
      });
  }

  setAvailableTimes(data = this.allAvailableTimes) {
		let self = this,
			  interval = 0.5;

		this.http.get(`${this.API}/api/danica-duration/` + self._currentPatient.clinicID)
		.map((res: any) => res)
		.subscribe(
			result => {
				interval = parseInt(result.response);

				self.splitTimes(data, interval);
			},
			error => {
				self.splitTimes(data, 30);
			}
		);
	}

  timeDifference(from, to) {
    let diff = (to.getTime() - from.getTime()) / 1000;

    diff /= (60);

		return Math.abs(Math.round(diff));
	}

  getFormattedDate(date) {
		// Safari and IE don't support the YYYY-MM-DD format, so it's converted to YYYY/MM/DD. Note - should use Moment.js to maintain the same formats
		return date.replace(/-/g, '/');
	}

  splitTimes(data, duration) {
		let self = this,
			  times = [];

		if (data) {

      const getTimeDiapozone = (duration, difference, period, from_time, to_time, date) => {
        const testDate = moment(),
              hours = from_time.split(':')[0],
              minutes = from_time.split(':')[1];

		    testDate.hours(hours).minutes(minutes);
        testDate.add(period * duration, 'minutes');

        return {
          startTime: testDate.format('HH:mm') + ":00",
          endTime: testDate.add(duration, 'minutes').format('HH:mm') + ":00"
        };
      }

			for (var therapist in data) {
				data[therapist].map((time) => {
          let difference = self.timeDifference(new Date(self.getFormattedDate(time.from)), new Date(self.getFormattedDate(time.to)));

					if (difference >= duration) {
            let from = time.from.split(" "),
                to = time.to.split(" "),
                date = from[0],
                from_time = from[1],
                to_time = to[1],
                differenceInTimes = Math.floor(difference/duration);

					  for (let i = 0; i < differenceInTimes; i++) {
              const timeObj = getTimeDiapozone(duration, difference, i, from_time, to_time, date),
                    startTime = timeObj.startTime,
                    endTime = timeObj.endTime,
                    id = date + ' ' + startTime + "_" + date + " " + endTime,
                    timeSlot = {
                      _id: id,
                      startTime,
                      endTime
                    };

              if (!(date in times)) {
                times[date] = [];
              }

              if (times[date] !== undefined) {

                if (times[date][id]) {
                  times[date][id]['therapistId'].push(therapist);
                } else {
                  timeSlot['therapistId'] = [therapist];
                  times[date][id] = timeSlot;
                }
              }
            }
					}
				});
			}
		}

		self.setHighlightedDates(Object.keys(times));
		self.availableTimes = times[self.selectedDate];

		if (self.availableTimes != undefined) {
			var k = Object.keys(self.availableTimes);

			self.availableTimes = k.map( key => self.availableTimes[key]);
			self.availableTimes.sort((a,b) => a.startTime.localeCompare(b.startTime));
		}
	}

  setHighlightedDates(dates){
    let copy = JSON.parse(JSON.stringify(this.myDatePickerOptions)),
        highlighted = dates.map(el => {
          let d = {
            year: parseInt(el.substring(0, 4)),
            month: parseInt(el.substring(5, 7)),
            day: parseInt(el.substring(8))
          };

          return d;
        });

    copy.highlightDates = highlighted;

    let yesterday = new Date();

    yesterday.setDate(yesterday.getDate() - 1);

    copy.disableUntil = {year: yesterday.getFullYear(), month: yesterday.getMonth() + 1, day: yesterday.getDate()};

    this.myDatePickerOptions = copy;
  }

  selectTime(time: any) {

    if (this.selectedTime === time) {
        this.selectedTime = "";
        this.therapist = "";

        localStorage.setItem("selectedTime", JSON.stringify(this.selectedTime));
        localStorage.setItem("therapist", JSON.stringify(this.therapist));
    } else {
        this.selectedTime = time;
        this.therapist = time.therapistId[0];

        localStorage.setItem("selectedTime", JSON.stringify(this.selectedTime));
        localStorage.setItem("therapist", JSON.stringify(this.therapist));
    }
  }

  // dateChanged callback function called when the user select the date. This is mandatory callback
  // in this option. There are also optional inputFieldChanged and calendarViewChanged callbacks.
  onDateChanged(event: IMyDateModel) {
    let self = this;

    self.selectedDate = event.formatted;
    self.selectedTherapist = "";
    self.selectedTime = "";
    self.availableTimes = [];

    self.setAvailableTimes();
  }

  buildDate(yyyy, mm, dd) {
      let day = String(dd);

      if (dd < 10) {
          day = '0' + dd;
      }

      let month = "";

      if (mm < 10) {
          month = '0' + mm;
      }

      return [yyyy + '-' + month + '-' + day];
  }

  goBack() {
    this._location.back();
  }

  navigateToBodyParts() {
    let params = {
      "cpr": this.params.cpr,
      "clinic-id": this.params['clinic-id'],
      "bodyParts": this.params['bodyParts'],
      'token': this.params['token']
    };

    let navigationExtras: NavigationExtras = {queryParams: params};

    this.router.navigate(['/body-parts'], navigationExtras);
  }

  finishBooking() {
    let patient = this.params;



    this.router.navigate(['/profil'], { queryParams: patient });
  }
}
