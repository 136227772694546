import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../auth/service";
import {Router, ActivatedRoute, Params, NavigationExtras, Event, NavigationEnd} from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    logo = "assets/img/logo.png";
    welcomeString = '';
    currentUrl: string;

    constructor(
        public authService: AuthenticationService, private router: Router
    ) {
    }

    ngOnInit() {
        console.log('header On init');

        let test = this.authService.activeUserName;

        // console.log("LINK", this.router.routerState.snapshot.url);
        // console.log("router", this.router);
        this.router.events.subscribe((event:Event) => {
          if(event instanceof NavigationEnd ) {

            this.currentUrl = event.url;

            console.log(this.currentUrl);
            console.log("check", this.currentUrl.indexOf('/profil') > -1);
          }
        });
        // console.log('test', test);

        // this.welcomeString = this.getWelcomeString();
    }

    getWelcomeString() {

        // return this.service.activeUser();

        //
        // console.log('asd');
        //
        // let welcomeString = ''
        // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        //
        // if (currentUser !== null && currentUser.firstName !==  null && currentUser.firstName !==  null) {
        //     welcomeString = currentUser.data.firstName + ' ' + currentUser.data.lastName;
        // } else if (currentUser.data.CPRNumber !== null) {
        //     welcomeString = currentUser.data.CPRNumber;
        // }
        //
        // return welcomeString;
    }
}
