import { Component, OnInit } from '@angular/core';
import {
   ElementRef, EventEmitter, Input,  OnChanges,
  Output, Renderer, SimpleChange,    } from '@angular/core';

import * as $ from 'jquery';
// const _ = require('underscore');

@Component({
  selector: 'app-info-messages',
  templateUrl: './info-messages.component.html',
  styleUrls: ['./info-messages.component.scss']
})
export class InfoMessagesComponent implements OnInit {

  @Input() notification: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
      var self = this;

      self.showNotification(self.notification);
  }

  showNotification(currentNotification: any) {
      var self = this;
      var messageBlock = $('.message-block');
      messageBlock.attr("class","message-block");

      self.notification.message = currentNotification.message;
      self.notification.type = currentNotification.type;

      if(currentNotification.type === 'loading'){
        messageBlock.addClass("message-"+currentNotification.type);
      } else if(currentNotification.type === ''){
        //pass
      } else {
        messageBlock.addClass("message-"+currentNotification.type);
        setTimeout(function(){
            messageBlock.removeClass("message-"+currentNotification.type);
        }, 3000);
      }
  }

}
