import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { LoginForm } from '../login/login-form';

import { Response, Headers, RequestOptions } from "@angular/http";
import {HttpClient, HttpResponse} from '@angular/common/http';
import * as $ from 'jquery';

import { AuthenticationService } from '../auth/service';
import { SharedService } from '../auth/sharedService';
import { UtilsService } from '../../utils.service';

declare var require: any;
const _ = require('underscore');
// const jwt = require('jsonwebtoken');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    avatar = "assets/img/login.png";
    // API = this.u.getApi();
    API = '';

    modelLogin = new LoginForm('', '');
    notification = {
        message: "",
        type: ""
    };
    submitted = false;
    currentUser: any;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        public u: UtilsService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit() {
        this.API = this.u.getApi();

        let currentUser = localStorage.getItem('currentUser');
        if (currentUser !== null) {
            this.router.navigate(["/sog"]);
        } else {
          this.authenticationService.logout();
          
        }




        // let resp = this.getJwtToken();
        // console.log('my new token', resp);
    }

    // getJwtToken() {
    //     const dataForToken = {
    //         eml: 'digifus',
    //         per: [ ]
    //     };
    //
    //     const secret = process.env.APP_SECRET || 'sviestabeka';
    //
    //     console.log('my new secret', secret);
    //
    //     return jwt.sign(dataForToken, secret, { expiresIn: '10m' });
    // }

    // logout() {
    //     AuthenticationService.activeUser = null;
    //     localStorage.removeItem('currentUser');
    //     localStorage.removeItem('currentPatient');
    //     localStorage.removeItem('currentClinic');
    //     localStorage.removeItem('lastTherapist');
    //     localStorage.removeItem('bookings');
    //     localStorage.removeItem('offerBooking');
    //     localStorage.removeItem('allClinics');
    //     localStorage.removeItem('allTherapists');
    //     localStorage.removeItem('fisiqClinics');
    // }

    login() {
        var self = this,
            list = {
				email: $('#cpr').val(),
				password: $('#password').val()
			};

        this.http.post(`${this.API}/users/authenticate`, {
            username: list.email,
            password: list.password
        }).subscribe((res: any) => {
            this.currentUser = {};

            if (res.status === 200) {
                this.currentUser = res.response;

                localStorage.setItem('currentUser', JSON.stringify({
                        data: {
                            'username': this.currentUser.username
                        },
                        token: this.currentUser.token,
                        userActive: true,
                    }
                ));

                self.router.navigate(["/sog"]);
            }

            return this.currentUser;
        });
    }
}
